export const quotes = [
    {
        quote: "The Analytical Engine has no pretensions whatever to originate anything. It can do whatever we know how to order it to perform.",
        author: "Ada Lovelace",
        link: undefined, // TODO: link to a page about the author
    },
    {
        quote: "Be curious. Read widely. Try new things. I think a lot of what people call intelligence boils down to curiosity.",
        author: "Aaron Swartz",
    },
    {
        quote: "How one does anything is how one does everything.",
        author: "Anon",
    },
    {
        quote: "The computing scientist's main challenge is not to get confused by the complexities of his own making.",
        author: "Edsger W. Dijkstra",
    },
    {
        quote: "I would rather die of passion than of boredom.",
        author: "Vincent Van Gogh",
    },
    {
        quote: "If you don't fail at least 90% of the time, you're not aiming high enough.",
        author: "Alan Kay",
    },
    {
        quote: "If you don't finish then you're just busy, not productive.",
        author: "Anon",
    },
    {
        quote: "A good programmer is someone who always looks both ways before crossing a one-way street.",
        author: "Doug Linder",
    },
    {
        quote: "If you don't make mistakes, you're not working on hard enough problems.",
        author: "Frank Wilczek",
    },
    {
        quote: "Almost every programming language is overrated by its practitioners.",
        author: "Larry Wall",
    },
    {
        quote: "Developing tolerance for imperfection is the key factor in turning chronic starters into consistent finishers.",
        author: "Jon Acuff",
    },
];

// \ ['Simplicity does not precede complexity, but follows it.', '', '- Alan Perlis'],
// \ ['Optimization hinders evolution.', '', '- Alan Perlis'],
// \ ['It is better to have 100 functions operate on one data structure than 10 functions on 10 data structures.', '', '- Alan Perlis'],
// \ ['There is nothing quite so useless as doing with great efficiency something that should not be done at all.', '', '- Peter Drucker'],
// \ ['I think a lot of new programmers like to use advanced data structures and advanced language features as a way of demonstrating their ability. I call it the lion-tamer syndrome. Such demonstrations are impressive, but unless they actually translate into real wins for the project, avoid them.', '', '- Glyn Williams'],
// \ ['Always code as if the person who ends up maintaining your code is a violent psychopath who knows where you live.', '', '- John Woods'],
// \ ['Unix was not designed to stop its users from doing stupid things, as that would also stop them from doing clever things.'],
// \ ['Contrary to popular belief, Unix is user friendly. It just happens to be very selective about who it decides to make friends with.'],
// \ ['Perfection is achieved, not when there is nothing more to add, but when there is nothing left to take away.'],
// \ ['Abstraction is not about vagueness, it is about being precise at a new semantic level.', '', '- Edsger W. Dijkstra'],
// \ ['Think twice, code once.'],
// \ ['The question of whether computers can think is like the question of whether submarines can swim.', '', '- Edsger W. Dijkstra'],
// \ ['They did not know it was impossible, so they did it!', '', '- Mark Twain'],
// \ ['To understand recursion, one must first understand recursion.', '', '- Stephen Hawking'],
// \ ['Every great developer you know got there by solving problems they were unqualified to solve until they actually did it.', '', '- Patrick McKenzie'],
// \ ["The average user doesn't give a damn what happens, as long as (1) it works and (2) it's fast.", '', '- Daniel J. Bernstein'],
// \ ['What one programmer can do in one month, two programmers can do in two months.', '', '- Frederick P. Brooks'],
// \ ['There’s no such thing as a schemaless system. There are systems where you write your schema down and ones where you don’t.', '', '- Rich Hickey'],
// \ ['[monads] are generated, so to speak, by continual fulgurations of the Divinity', '', '-  Gottfried Leibniz'],
